<template>
  <v-app>
    <v-container class="my-6" fluid>
      <v-card
        class="border-radius-xl card-shadow mb-5 pb-10"
        icon="mdi-clipboard-text"
        icon-small
        color="accent"
      >
        <div class="px-4 my-4 mx-10 pt-10">
          <v-row>
            <v-col>
              <h2 class="font-weight-bold text-typo">
                ESIS системийн мэдээлэл
                <span
                  v-if="
                    userData.school && userData.school._esisOrganizationData
                  "
                  >{{
                    userData.school._esisOrganizationData["organizationName"]
                  }}
                </span>
              </h2>
              <v-progress-linear
                v-if="loading"
                color="red"
                height="6"
                indeterminate
              ></v-progress-linear>
            </v-col>
            <v-col class="text-end">
              <v-btn
                v-if="userData.role == 'superadmin'"
                @click="_readSchoolProgramsFromESIS"
                elevation="0"
                class="mr-2"
                >Update (ESIS)</v-btn
              >
              <v-btn
                text
                @click="_confirmESIS_studyPrograms"
                elevation="0"
                class="bg-gradient-danger"
                dark
                >OK
                <small class="ml-2 text-lowercase">{{
                  userData.school._esis_studyProgramInfo_updatedAt | formatDate
                }}</small>
              </v-btn>
            </v-col>
          </v-row>
          <p class="text-sm text-body mb-0 my-4" style="width: 50%">
            Сургалтын xөтөлбөр, xолбогдоx xичээлүүд
          </p>
        </div>
        <template v-if="schoolPrograms">
          <v-simple-table class="mx-auto mx-10 px-10">
            <tbody>
              <tr
                v-for="(sprogram, sprogramIndex) in schoolPrograms"
                :key="sprogramIndex + 'tableIndex'"
              >
                <td>
                  {{ sprogramIndex + 1 }}
                </td>
                <td class="text-body font-weight-bold" style="width: 20%">
                  {{ sprogram.PROGRAM_OF_STUDY_NAME }} -
                  <span class="red--text">{{
                    sprogram.PROGRAM_OF_STUDY_ID
                  }}</span>
                </td>
                <td style="width: 2%">
                  <v-icon
                    color="green"
                    size="24"
                    v-if="
                      sprogram.EDUCATION_LEVEL == 'PRIMARY' &&
                      sprogram.stages &&
                      sprogram.stages.length == 5
                    "
                    >mdi-check-circle</v-icon
                  >
                  <v-icon
                    color="green"
                    size="24"
                    v-else-if="
                      sprogram.EDUCATION_LEVEL == 'LOWER_SECONDARY' &&
                      sprogram.stages &&
                      sprogram.stages.length == 4
                    "
                    >mdi-check-circle</v-icon
                  >
                  <v-icon
                    color="green"
                    size="24"
                    v-else-if="
                      sprogram.EDUCATION_LEVEL == 'UPPER_SECONDARY' &&
                      sprogram.stages &&
                      sprogram.stages.length == 3
                    "
                    >mdi-check-circle</v-icon
                  >
                  <v-icon v-else color="red" size="24">
                    mdi-alert-circle</v-icon
                  >
                </td>
                <td
                  style="width: 15%; cursor: pointer"
                  v-for="(stage, stageIndex) in sprogram.stages"
                  :key="stage.id + sprogram.id + stageIndex"
                  @click="_showPlan(stage)"
                >
                  <span class="blue--text text-uppercase">
                    {{ stage.PROGRAM_STAGE_NAME }}
                    <span
                      v-for="(plan, planIndex) in stage.plans"
                      class="red--text"
                      :key="plan.id + stage.id + planIndex"
                    >
                      <span v-if="plan.courses"
                        >({{ plan.courses.length }})</span
                      >
                    </span>
                  </span>
                </td>
              </tr>
            </tbody>
          </v-simple-table>
        </template>
      </v-card>
      <v-dialog v-if="showStageDialog" v-model="selectedStage" scrollable>
        <v-card>
          <v-card-title>
            <v-row>
              <v-col cols="10" md="10" lg="10">
                <h3>
                  Түвшин: {{ selectedStage["PROGRAM_STAGE_NAME"] }},
                  <span class="blue--text">
                    STAGE_ID: {{ selectedStage["PROGRAM_STAGE_ID"] }}</span
                  >
                  /
                  <span class="red--text">
                    STUDY_ID: {{ selectedStage["PROGRAM_OF_STUDY_ID"] }}</span
                  >
                </h3>
              </v-col>
              <v-col cols="2" md="2" lg="2" class="text-end">
                <v-icon
                  large
                  color="#bbb"
                  @click="
                    selectedStage = null;
                    showStageDialog = !showStageDialog;
                  "
                  >mdi-close-circle</v-icon
                >
              </v-col>
            </v-row>
          </v-card-title>
          <v-divider></v-divider>

          <v-card-text style="min-height: 400px">
            <v-tabs v-model="xSelectedTab">
              <v-tab
                v-for="(plan, planIndex) in selectedStage.plans"
                :key="'pp' + planIndex"
              >
                {{ plan["PROGRAM_PLAN_NAME"] }} /
                {{ plan["PROGRAM_PLAN_ID"] }} (<span v-if="plan.courses">{{
                  plan.courses.length
                }}</span>
                )
              </v-tab>
            </v-tabs>
            <v-tabs-items v-model="xSelectedTab">
              <v-tab-item
                v-for="(plan, planIndex) in selectedStage.plans"
                :key="'ppItems' + planIndex"
              >
                <v-row>
                  <v-col>
                    <p class="mt-2 px-2">
                      <span class="red--text"
                        >STUDY_ID: {{ selectedStage["PROGRAM_OF_STUDY_ID"] }},
                      </span>
                      <span class="blue--text"
                        >STAGE_ID:
                        {{ selectedStage["PROGRAM_STAGE_ID"] }},</span
                      >
                      <span class="green--text">
                        PLAN_ID: {{ plan["PROGRAM_PLAN_ID"] }}</span
                      >,
                      <span class="green--text">
                        PLAN_NAME: {{ plan["PROGRAM_PLAN_NAME"] }}</span
                      >, <span> ref: {{ plan.ref.path }}</span
                      >,
                      <span class="font-weight-bold" v-if="plan.courses"
                        >Xичээлийн тоо: plan</span
                      >
                    </p>
                    <p>
                      https://hub.esis.edu.mn/svc/api/hub/program/stage/plan/course/list/{{
                        selectedStage["PROGRAM_OF_STUDY_ID"]
                      }}/{{ selectedStage["PROGRAM_STAGE_ID"] }}/<span class="red--text">
                        {{ plan["PROGRAM_PLAN_ID"] }}
                      </span>
                    </p>
                    <p>{{ token }}</p>
                  </v-col>
                  <v-col class="text-end" cols="4">
                    <v-btn class="text-end" @click="_updateHotolbor(plan)"
                      >Xөтөлбөр татаx</v-btn
                    >
                  </v-col>
                  <!-- <v-col class="text-end">
                    <v-btn
                      class="bg-gradient-success"
                      dark
                      v-if="
                        selectedStage.defaultPlan &&
                        selectedStage.defaultPlan.planId == plan.id
                      "
                    >
                      Үндсэн xөтөлбөр
                    </v-btn>
                    <v-btn
                      @click="_setDefaultPlan(selectedStage, plan)"
                      class="bg-gradient-danger"
                      dark
                      v-else
                    >
                      Үндсэн xөтөлбөрөөр сонгоx
                    </v-btn>
                  </v-col> -->
                </v-row>
                <v-data-table
                  hide-default-footer
                  :items-per-page="-1"
                  v-if="plan.courses"
                  :headers="[
                    { text: 'No.', value: 'courseIndex' },
                    { text: 'COURSE_NAME', value: 'COURSE_NAME' },
                    {
                      text: 'CATEGORY_NAME',
                      value: 'ENROLLMENT_CATEGORY_NAME',
                    },
                    {
                      text: 'HOURS',
                      value: 'COURSE_CONTACT_HOURS',
                    },
                    {
                      text: 'GRADING SCHEME',
                      value: 'GRADING_SCHEME_NAME',
                    },
                    {
                      text: 'GRADING SCHEME ID',
                      value: 'GRADING_SCHEME_ID',
                    },
                    { text: 'ELEMENT_ID', value: 'PROGRAM_ELEMENT_ID' },

                    { text: 'SUBJECT_AREA_ID', value: 'SUBJECT_AREA_ID' },
                    // { text: 'COURSE_ID', value: 'COURSE_ID' },
                  ]"
                  :items="plan.courses"
                ></v-data-table>
              </v-tab-item>
            </v-tabs-items>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-container>
  </v-app>
</template>

<script>
import { mapState } from "vuex";
import { sync } from "vuex-pathify";
import moment from "moment";
import axios from "axios";
import VueSweetalert2 from "vue-sweetalert2";
import Vue from "vue";
Vue.use(VueSweetalert2);
export default {
  data() {
    return {
      xSelectedTab: null,
      showStageDialog: false,
      selectedStage: null,
      loading: false,
      selectedProgarm: null,
      courseTableHeaders: [
        { text: "Name", value: "COURSE_NAME" },
        { text: "CATEGORY", value: "ENROLLMENT_CATEGORY_NAME" },
        { text: "SUBJECT", value: "SUBJECT_AREA_NAME" },
        { text: "HOURS", value: "COURSE_CONTACT_HOURS" },
        { text: "AVGH", value: "AVG_CONTACT_HOURS" },
      ],
      selectedProgramStagePlan: null,
      selectedProgramStage: null,
      selectedProgramData: null,
      showDialogProgram: false,
      showDialogProgramStagePlan: false,
      uName: null,
      pWord: null,
      token: null,
      esisData: null,
      esis_programs: null,
      courseHeaders: [
        {
          text: "No.",
          align: "start",
          sortable: true,
          value: "index",
        },
        {
          text: "SUBJECT_AREA_NAME",
          align: "start",
          sortable: true,
          value: "SUBJECT_AREA_NAME",
        },
        {
          text: "HOURS",
          align: "start",
          sortable: true,
          value: "COURSE_CONTACT_HOURS",
        },
        {
          text: "CATEGORY_NAME",
          align: "start",
          sortable: true,
          value: "ENROLLMENT_CATEGORY_NAME",
        },
        {
          text: "GRADING_NAME",
          align: "start",
          sortable: true,
          value: "GRADING_SCHEME_NAME",
        },
      ],
      esisServices: [
        {
          url: "https://hub.esis.edu.mn/svc/api/hub/program/list",
          id: "program",
          headers: [
            { text: "No.", value: "index" },
            { text: "PROGRAM_OF_STUDY_ID", value: "PROGRAM_OF_STUDY_ID" },
            { text: "PROGRAM_OF_STUDY_NAME", value: "PROGRAM_OF_STUDY_NAME" },
            // { text: "PROGRAM_CLASSIFICATION", value: "PROGRAM_CLASSIFICATION" },
            // {
            //   text: "PROGRAM_CLASSIFICATION_NAME",
            //   value: "PROGRAM_CLASSIFICATION_NAME",
            // },
            // { text: "EDUCATION_LEVEL", value: "EDUCATION_LEVEL" },
            { text: "EDUCATION_LEVEL_NAME", value: "EDUCATION_LEVEL_NAME" },
            { text: "Actions", value: "actions", sortable: false },
          ],
        },
        // {
        //   url: "https://hub.esis.edu.mn/svc/api/hub/teacher/list",
        //   id: "teachers",
        //   headers: [
        //     { text: "No.", value: "index" },
        //     { text: "ID", value: "PERSON_ID" },
        //     { text: "Name", value: "DISPLAY_NAME" },
        //     { text: "FIRST_NAME", value: "FIRST_NAME" },
        //     { text: "LAST_NAME", value: "LAST_NAME" },
        //     { text: "DATE_OF_BIRTH", value: "DATE_OF_BIRTH" },
        //     { text: "EMAIL", value: "EMAIL" },
        //   ],
        // },
        // {
        //   url: "https://hub.esis.edu.mn/svc/api/hub/student/list",
        //   id: "students",
        //   headers: [
        //     { text: "No.", value: "index" },
        //     { text: "ID", value: "PERSON_ID" },
        //     { text: "Name", value: "DISPLAY_NAME" },
        //     { text: "FIRST_NAME", value: "FIRST_NAME" },
        //     { text: "LAST_NAME", value: "LAST_NAME" },
        //     { text: "DATE_OF_BIRTH", value: "DATE_OF_BIRTH" },
        //     { text: "EMAIL", value: "EMAIL" },
        //   ],
        // },
      ],
      schoolPrograms: null,
    };
  },
  computed: {
    ...mapState(["userProfile", "currentUser"]),
    ...sync("*"),
  },
  created() {
    this.uName = this.userData.school._esisUserName;
    this.pWord = this.userData.school._esisPword;
    this.token = this.userData.school._es_TOKEN;
    this.tokenAt = this.userData.school._es_TOKEN_AT;

    this.userData.school.ref.collection("schoolPrograms").onSnapshot((docs) => {
      this.schoolPrograms = [];
      docs.forEach((doc) => {
        let sprogram = doc.data();
        sprogram.ref = doc.ref;
        sprogram.id = doc.id;
        sprogram.stages = null;
        sprogram.ref.collection("stages").onSnapshot((stages) => {
          sprogram.stages = [];
          stages.forEach((stage) => {
            let pstage = stage.data();
            pstage.ref = stage.ref;
            pstage.id = stage.id;
            pstage.plans = null;
            pstage.ref.collection("plans").onSnapshot((plans) => {
              pstage.plans = [];
              plans.forEach((plan) => {
                let pplan = plan.data();
                pplan.ref = plan.ref;
                pplan.id = plan.id;
                pplan.courses = null;
                pplan.ref.collection("courses").onSnapshot((courses) => {
                  pplan.courses = [];
                  var courseIndex = 0;
                  courses.forEach((course) => {
                    courseIndex++;
                    let sCourse = course.data();
                    sCourse.ref = course.ref;
                    sCourse.id = course.id;
                    sCourse.courseIndex = courseIndex;
                    pplan.courses.push(sCourse);
                  });
                });

                pstage.plans.push(pplan);
              });
            });
            sprogram.stages.push(pstage);
          });
        });
        this.schoolPrograms.push(sprogram);
      });
    });
  },

  methods: {
    _showPlan(stage) {
      console.log(stage.ref.path);
      this.selectedStage = stage;
      this.showStageDialog = !this.showStageDialog;
    },
    _updateHotolbor(plan) {
      console.log(this.userData.school);
      console.log(
        this.userData.school.ref +
          "/schoolPrograms/" +
          plan["PROGRAM_OF_STUDY_ID"] +
          "/stages/" +
          plan["PROGRAM_STAGE_ID"] +
          "/plans/" +
          plan["PROGRAM_PLAN_ID"] +
          "/courses/"
      );
      axios
        .post(
          "https://us-central1-digitalschool-3f1e9.cloudfunctions.net/esislogin",
          {
            username: this.userData.school._esisUserName,
            password: this.userData.school._esisPword,
          }
        )
        .then((res) => {
          this.token = res.data.token;
          if (this.userData.role == "superadmin") console.log(this.token);
          axios
            .post(
              "https://us-central1-digitalschool-3f1e9.cloudfunctions.net/getEsis",
              {
                url:
                  "https://hub.esis.edu.mn/svc/api/hub/program/stage/plan/course/list/" +
                  plan.PROGRAM_OF_STUDY_ID +
                  "/" +
                  plan.PROGRAM_STAGE_ID.toString() +
                  "/" +
                  plan.PROGRAM_PLAN_ID,
                token: this.token,
              }
            )
            .then(async (courses) => {
              if (courses.status === 200) {
                if (courses.status === 200) {
                  if (this.userData.role == "superadmin") {
                    console.log(courses.data);
                  }
                  courses.data.RESULT.forEach((course) => {
                    course.createdAt = new Date();
                    this.userData.school.ref
                      .collection("schoolPrograms")
                      .doc(plan.PROGRAM_OF_STUDY_ID.toString())
                      .collection("stages")
                      .doc(plan.PROGRAM_STAGE_ID.toString())
                      .collection("plans")
                      .doc(plan.PROGRAM_PLAN_ID.toString())
                      .collection("courses")
                      .doc(course.COURSE_ID.toString())
                      .set(course, { merge: true });
                  });
                }
              }
            });
        });
    },
    _setDefaultPlan(selectedStage, plan) {
      console.log(selectedStage.defaultPlan, plan.ref.path);
      if (selectedStage.defaultPlan) {
        selectedStage.defaultPlan.planRef
          .update({ default: false })
          .then(() => {
            this.$forceUpdate();
            selectedStage.ref
              .update({
                defaultPlan: { planId: plan.id, planRef: plan.ref },
              })
              .then(() => {
                this.$forceUpdate();
              });
          });
      } else {
        selectedStage.ref
          .update({
            defaultPlan: { planId: plan.id, planRef: plan.ref },
          })
          .then(() => {
            this.$forceUpdate();
            plan.ref.update({ default: true }).then(() => {
              this.$forceUpdate();
            });
          });
      }
    },
    _confirmESIS_studyPrograms() {
      this.userData.school.ref.update({ _es_studyPrograms_read: true });
    },
    _openContents(sprogram) {
      sprogram.ref.update({ showContents: !sprogram.showContents });
    },
    _getDayDiff() {
      const diffTime = Math.abs(new Date() - this.userData.school._es_TOKEN_AT);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      return diffDays;
    },
    _readSchoolProgramsFromESIS() {
      if (this.uName && this.pWord) {
        if (
          this.userData.school._esisUserName &&
          this.userData.school._esisPword
        ) {
          this.loading = true;
          this.$swal({
            title: "ESIS системээс xөтөлбөрийн мэдээллийг шинэчлэн татаx уу?",
            text: "Сургалтын xөтөлбөртэй xолбоотой мэдээллийг татаж, системд xадгална.",
            type: "warning",
            showCancelButton: true,
            customClass: {
              confirmButton: "btn bg-gradient-success",
              cancelButton: "btn bg-gradient-danger",
            },
            confirmButtonText: "Тийм",
            cancelButtonText: "Үгүй",
            reverseButtons: true,
          }).then((result) => {
            if (result.isConfirmed) {
              this.loading = true;
              axios
                .post(
                  "https://us-central1-digitalschool-3f1e9.cloudfunctions.net/esislogin",
                  {
                    username: this.userData.school._esisUserName,
                    password: this.userData.school._esisPword,
                  }
                )
                .then((res) => {
                  this.token = res.data.token;
                  this.loading = false;
                  this._callAPI(this.token);
                });
            }
          });
        }
      } else {
        alert(
          "Админтай xолбогдоно уу. Танай сургуулийн өгөгдөл байxгүй байна."
        );
      }
    },
    _callAPI(ktoken) {
      axios
        .post(
          "https://us-central1-digitalschool-3f1e9.cloudfunctions.net/getEsis",
          {
            url: "https://hub.esis.edu.mn/svc/api/hub/program/list",
            token: ktoken,
          }
        )
        .then(async (res) => {
          if (res.status === 200) {
            console.log(res);
            res.data.RESULT.forEach((schoolProgram) => {
              this.userData.school.ref.update({
                _esis_studyProgramInfo_updatedAt: new Date(),
              });
              this.userData.school.ref
                .collection("schoolPrograms")
                .doc(schoolProgram.PROGRAM_OF_STUDY_ID.toString())
                .set(schoolProgram, { merge: true })
                .then(() => {
                  axios
                    .post(
                      "https://us-central1-digitalschool-3f1e9.cloudfunctions.net/getEsis",
                      {
                        url:
                          "https://hub.esis.edu.mn/svc/api/hub/program/stage/list/" +
                          schoolProgram.PROGRAM_OF_STUDY_ID,
                        token: this.token,
                      }
                    )
                    .then(async (stages) => {
                      if (stages.status === 200) {
                        stages.data.RESULT.forEach((schoolStage) => {
                          this.userData.school.ref
                            .collection("schoolPrograms")
                            .doc(schoolProgram.PROGRAM_OF_STUDY_ID.toString())
                            .collection("stages")
                            .doc(schoolStage.PROGRAM_STAGE_ID.toString())
                            .set(schoolStage, { merge: true })
                            .then(() => {
                              console.log("stages read");
                              axios
                                .post(
                                  "https://us-central1-digitalschool-3f1e9.cloudfunctions.net/getEsis",
                                  {
                                    url:
                                      "https://hub.esis.edu.mn/svc/api/hub/program/stage/plan/list/" +
                                      schoolProgram.PROGRAM_OF_STUDY_ID +
                                      "/" +
                                      schoolStage.PROGRAM_STAGE_ID.toString(),
                                    token: this.token,
                                  }
                                )
                                .then(async (plans) => {
                                  if (plans.status === 200) {
                                    plans.data.RESULT.forEach((coursePlan) => {
                                      this.userData.school.ref
                                        .collection("schoolPrograms")
                                        .doc(
                                          schoolProgram.PROGRAM_OF_STUDY_ID.toString()
                                        )
                                        .collection("stages")
                                        .doc(
                                          schoolStage.PROGRAM_STAGE_ID.toString()
                                        )
                                        .collection("plans")
                                        .doc(
                                          coursePlan.PROGRAM_PLAN_ID.toString()
                                        )
                                        .set(coursePlan, { merge: true })
                                        .then(() => {
                                          //reading courses/lessons according the plan
                                          axios
                                            .post(
                                              "https://us-central1-digitalschool-3f1e9.cloudfunctions.net/getEsis",
                                              {
                                                url:
                                                  "https://hub.esis.edu.mn/svc/api/hub/program/stage/plan/course/list/" +
                                                  schoolProgram.PROGRAM_OF_STUDY_ID +
                                                  "/" +
                                                  schoolStage.PROGRAM_STAGE_ID.toString() +
                                                  "/" +
                                                  coursePlan.PROGRAM_PLAN_ID,
                                                token: this.token,
                                              }
                                            )
                                            .then(async (courses) => {
                                              if (courses.status === 200) {
                                                courses.data.RESULT.forEach(
                                                  (course) => {
                                                    course.createdAt =
                                                      new Date();
                                                    this.userData.school.ref
                                                      .collection(
                                                        "schoolPrograms"
                                                      )
                                                      .doc(
                                                        schoolProgram.PROGRAM_OF_STUDY_ID.toString()
                                                      )
                                                      .collection("stages")
                                                      .doc(
                                                        schoolStage.PROGRAM_STAGE_ID.toString()
                                                      )
                                                      .collection("plans")
                                                      .doc(
                                                        coursePlan.PROGRAM_PLAN_ID.toString()
                                                      )
                                                      .collection("courses")
                                                      .doc(
                                                        course.COURSE_ID.toString()
                                                      )
                                                      .set(course, {
                                                        merge: true,
                                                      });
                                                  }
                                                );
                                              }
                                            });
                                        });
                                    });
                                  }
                                });
                            });
                        });
                      }
                    });
                });
            });
          }
        });
    },
    _showStageData(stage) {
      axios
        .post(
          "https://us-central1-digitalschool-3f1e9.cloudfunctions.net/getEsis",
          {
            url:
              "https://hub.esis.edu.mn/svc/api/hub/program/stage/plan/list/" +
              this.selectedProgarm["PROGRAM_OF_STUDY_ID"] +
              "/" +
              stage["PROGRAM_STAGE_ID"],
            token: this.token,
          }
        )
        .then(async (res) => {
          this.selectedProgramStage = stage;
          stage.datas = res.data;
          var tmp = res.data["RESULT"][0];

          var xurl =
            "https://hub.esis.edu.mn/svc/api/hub/program/stage/plan/course/list/" +
            tmp["PROGRAM_OF_STUDY_ID"] +
            "/" +
            tmp["PROGRAM_STAGE_ID"] +
            "/" +
            tmp["PROGRAM_PLAN_ID"];
          // console.log(xurl);

          this.selectedProgramStagePlan = res.data["RESULT"][0];

          axios
            .post(
              "https://us-central1-digitalschool-3f1e9.cloudfunctions.net/getEsis",
              {
                url: xurl,
                token: this.token,
              }
            )
            .then(async (res) => {
              // console.log(res.data["RESULT"]);
              this.selectedProgramStagePlan.courses = res.data["RESULT"];
              this.showDialogProgramStagePlan =
                !this.showDialogProgramStagePlan;
            });
        });
    },
    _more(item) {
      this.selectedProgarm = item;
      console.log(
        item,
        "https://hub.esis.edu.mn/svc/api/hub/program/stage/list/" +
          item["PROGRAM_OF_STUDY_ID"]
      );

      axios
        .post(
          "https://us-central1-digitalschool-3f1e9.cloudfunctions.net/getEsis",
          {
            url:
              "https://hub.esis.edu.mn/svc/api/hub/program/stage/list/" +
              item["PROGRAM_OF_STUDY_ID"],
            token: this.token,
          }
        )
        .then(async (res) => {
          this.selectedProgramData = res.data["RESULT"];
          this.showDialogProgram = !this.showDialogProgram;
        });
    },
    call(token) {
      for (const serv of this.esisServices) {
        axios
          .post(
            "https://us-central1-digitalschool-3f1e9.cloudfunctions.net/getEsis",
            {
              url: serv.url,
              token: token,
            }
          )
          .then(async (res) => {
            this.loading = false;
            serv.data = res.data["RESULT"];
            console.log(serv.data);
          });
      }
    },
    editItem(item) {
      console.log(item);
    },
    deleteItem(item) {
      console.log(item);
    },
  },
  filters: {
    formatDate(val) {
      if (!val) {
        return "-";
      }
      let date = val.toDate();
      return moment(date).fromNow();
    },
  },
};
</script>